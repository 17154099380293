<template>
  <div>
    <Breadcrumb v-bind:item="'Terms & Conditions'"/>
    <div class="privacy-wrap">
      <SectionHeader v-bind:title="this.title"/>
      <div class="privacy">
          <h1>Terms & Conditions</h1>
          <div class="paragraph">
            <p></p>
            <h2>PRIVACY POLICY</h2>
            <p>We are the California Wine Institute (“Wine Institute”), a public policy organization supporting California wine producers. Ensuring the privacy and security of your personal information is important to us. Our physical offices are located in San Francisco, Sacramento, and Washington, D.C.</p>
            <p><strong>What personal data we collect and why we collect it</strong></p>
            <p>We collect information that our visitors volunteer to us, including but not limited to name, email, address, city, state, country and post code (collectively, “Personal Information”). By volunteering Personal Information to Wine Institute, you are agreeing to allow us to use your data for the purposes listed below.</p>
            <p>Information collected on this website is used in our marketing, promotion, and advertising programs.</p>
            <p><strong>Email subscription</strong></p>
            <p>If you have chosen to subscribe to our emails, we store Personal Information for the purpose of distributing future email communications. By signing up for our emails you are agreeing to allow Wine Institute to use your data for outreach and marketing purposes.</p>
            <p>If you would like to unsubscribe to our email list, please contact us at ukcontacts@wineinstitute.org. We will remove your name from our email list as soon as possible. Please recognize that you may receive another email before we are able to remove you.</p>
            <p><strong>Contact forms</strong></p>
            <p>When you submit a contact form through our “contact us” page, we capture Personal Information such as your name and email address. We keep Personal Information obtained through contact form submissions only as long as is necessary for customer service purposes. The information submitted through our “contact us” form is not used for marketing purposes.</p>
            <p><strong>Analytics</strong></p>
            <p>We use Google Analytics to track website traffic. This tool stores cookies on users’ PCs to keep track of usage data. You can find Google Analytics privacy policy here: <a href="https://support.google.com/analytics/answer/6004245?hl=en">https://support.google.com/analytics/answer/6004245?hl=en</a>. This link allows you to opt out of Google Analytics data tracking: <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a></p>
            <p><strong>Embedded content from other websites</strong></p>
            <p>Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website. These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website. </p>
            <p><strong>Cookies</strong></p>
            <p>When you visit our website, we will set up several cookies to save your login information and your screen display choices. </p>
            <p><strong>Who we share your data with</strong></p>
            <p>We will not sell, exchange, rent or otherwise distribute your personal information to other organizations or unaffiliated third parties, except to the extent required by law. We sometimes contract with other companies (i.e. cloud-based services and third party service providers) and individuals to perform functions or services on our behalf. These companies and individuals may have access to Personal Information needed to perform their functions but are restricted from using the Personal Information for purposes other than providing services for us. </p>
            <p><strong>What rights you have over your data</strong></p>
            <p>You can request to receive a file of the personal data we hold about you, including any data you have provided to us. You may also request that we delete any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.</p>
            <p><strong>Other</strong></p>
            <p>This Privacy Policy was last updated on January 9, 2020. We may update this Policy from time to time. Changes to our Privacy Policy are effective immediately upon posting. Please check this Privacy Policy on a regular basis.</p>
            <p><strong>Disclaimer</strong></p>
            <p>All information on this website is provided as an informational aid only and should not be relied upon in any way. Data may be out-of-date or incomplete.</p>
            <p>&lrm;</p>
            <h2>TERMS OF USE</h2>
            <p><strong>General Terms of Use</strong></p>
            <p>This Terms of Use Agreement (“Agreement”) constitutes a legally binding agreement made between you, whether personally or on behalf of an entity (“user” or “you”) and Wine Institute and its affiliated companies, concerning your access to and use of this website as well as any other media form related or connected thereto (collectively, the “Website”). Supplemental terms and conditions or documents that may be posted on the Website from time to time, are hereby expressly incorporated into this Agreement by reference.</p>
            <p>Wine Institute makes no representation that the Website is available in other locations other than where it is operated by Wine Institute. The information provided on the Website is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation. Accordingly, those persons who choose to access the Website from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.</p>
            <p>YOU ACCEPT AND AGREE TO BE BOUND BY THIS AGREEMENT BY ACKNOWLEDGING SUCH ACCEPTANCE BY CONTINUING TO USE THE WEBSITE. IF YOU DO NOT AGREE TO ABIDE BY THIS AGREEMENT, OR TO MODIFICATIONS THAT WINE INSTITUTE MAY MAKE TO THIS AGREEMENT IN THE FUTURE, DO NOT SUE OR ACCESS OR CONTINUE TO USE OR ACCESS THE COMPANY SERVICES OR THE WEBSITE.</p>
            <p><strong>User Representations</strong></p>
            <p>By using Wine Institute website, you represent and warrant that:</p>
            <p>1. All information you submit is truthful and accurate;</p>
            <p>2. You will maintain the accuracy of such information;</p>
            <p>3. You are not a minor in the jurisdiction in which you reside, or if a minor, you have received parental permission to use this Website; and</p>
            <p>4. Your use of the Wine Institute website does not violate any applicable law or regulation.</p>
            <p><strong>Prohibited Activities</strong></p>
            <p>You may not access or use the Website for any purpose other that than for which Wine Institute makes it available. The Website may not be used in connection with any commercial endeavours except those that are specifically endorsed or approved by Wine Institute.</p>
            <p></p>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import Breadcrumb from '@/components/snippets/Breadcrumb.vue'
import SectionHeader from '@/components/snippets/SectionHeader.vue';

export default {
    components: { 
        Breadcrumb,
        SectionHeader
    },

    data() {
        return {
            title: 'Terms & Conditions'
        }
    },
}
</script>
